<template>
    <component :is="tag" ref="container" v-bind="attrs" :style="style" @transitionend="onTransitionEnd">
        <slot v-if="$slots.default"/>
        <template v-else>
            {{modelValue}}
        </template>
    </component>
</template>

<script>
    export default {
        name: "SlideUpDown",
        props: {
            modelValue: {
                type: [Number, String, Boolean, Object],
                default: null
            },
            duration: {
                type: [Number, String],
                default: 500,
            },
            tag: {
                type: String,
                default: "div",
            },
            useHidden: {
                type: Boolean,
                default: true,
            },
        },
        emits: ["open-start", "close-start", "open-end", "close-end"],
        data: () => ({
            style: {},
            initial: false,
            hidden: false,
        }),

        computed: {
            el() {
                return this.$refs.container;
            },

            attrs() {
                const attrs = {
                    "aria-hidden": !this.modelValue || null,
                    "aria-expanded": this.modelValue || null
                };

                if (this.useHidden) {
                    attrs.hidden = this.hidden;
                }

                return attrs;
            },
        },

        watch: {
            modelValue() {
                this.layout();
            },
        },

        mounted() {
            this.layout();
            this.initial = true;
        },

        created() {
            this.hidden = !this.modelValue;
        },

        methods: {
            layout() {
                if (!this.el) return;
                if (this.modelValue) {
                    this.hidden = false;
                    this.$emit("open-start");
                    if (this.initial) {
                        this.setHeight("0px", () => this.el.scrollHeight + "px");
                    }
                } else {
                    this.$emit("close-start");
                    this.setHeight(this.el.scrollHeight + "px", () => "0px");
                }
            },

            asap(callback) {
                if (!this.initial) {
                    callback();
                } else {
                    this.$nextTick(callback);
                }
            },

            setHeight(temp, afterRelayout) {
                if (!this.el) return;
                this.style = {height: temp};

                this.asap(() => {
                    // force relayout so the animation will run
                    this.__ = this.el.scrollHeight;

                    this.style = {
                        height: afterRelayout(),
                        overflow: "hidden",
                        "transition-property": "height",
                        "transition-duration": this.duration + "ms",
                    };
                });
            },

            onTransitionEnd(event) {
                // Don't do anything if the transition doesn't belong to the container
                if (event.target !== this.el) return;

                if (this.modelValue) {
                    this.style = {};
                    this.$emit("open-end");
                } else {
                    this.style = {
                        height: "0",
                        overflow: "hidden",
                    };
                    this.hidden = true;
                    this.$emit("close-end");
                }
            },
        },
    };
</script>
