<template>
    <div class="@relative" :class="{addErrorBorder}">
        <input
            v-validity="message"
            type="text"
            required
            class="hiddenRequiredInput"
            tabindex="-1"
            onfocus="this.blur()"
            onchange="this.value = ''"
            oninput="this.value = ''"
            autocomplete="none"
        >
    </div>
</template>

<script>
    import {validity} from "@songfinch/shared/mixins/validity_directives";

    export default {
        name: "RequiredFormHelper",
        directives: {validity},
        props: {
            addErrorBorder: Boolean,
            message: {type: String, default: "Please fill out this field."},
            borderZIndex: {
                type: [Number, String],
                default: -1
            }
        }
    };
</script>

<style scoped>
    .addErrorBorder .hiddenRequiredInput {
        width: 100% !important;
        height: 50px !important;
        opacity: 1 !important;
        left: 0 !important;
        bottom: 0 !important;
        top: auto !important;
        transform: none !important;
        z-index: v-bind(borderZIndex) !important;
    }

    .hiddenRequiredInput {
        display: block !important;
        width: 1px !important;
        height: 1px !important;
        opacity: 0 !important;
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        padding: 0 !important;
    }
</style>
